import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useQuery, useMutation } from '@apollo/react-hooks';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { GET_PATIENT } from '../../../graphql/queries';
import { UPDATE_PATIENT } from '../../../graphql/mutations';
import { isEmpty } from '../../../utils/ObjectUtils';
import { PatientForm } from '../PatientForm';
import Loading from '../../Common/Loading';
import useStyles from '../styled/PatientMakeStyles';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const EditPatientDialog = ({ open, onClose, patientId }) => {
  if (!patientId) return null;

  const { t } = useTranslation();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [serverError, setServerError] = useState(false);
  const classes = useStyles();

  const { loading, data } = useQuery(
    GET_PATIENT,
    {
      variables: {
        uuid: patientId,
      },
    },
  );

  const [updatePatient, { loading: submitting }] = useMutation(UPDATE_PATIENT, {
    onCompleted() {
      onClose();
    },
    onError: (error) => {
      if (error && error.graphQLErrors && error.graphQLErrors.length) {
        setServerError(error.graphQLErrors[0].message);
      }
    },
  });

  const submitAction = (values) => {
    let patient = { ...values, uuid: patientId };
    delete patient.hospitalUuid;
    updatePatient({ variables: { patient } }).then();
  };

  // TODO: remove hardcoded hospital once implemented in backend
  const initialPatient = (loading || isEmpty(data)) ? {} : {
    customId: data.patient ? data.patient.customId || '' : '',
    name: data.patient ? data.patient.name || '' : '',
    surname: data.patient ? data.patient.surname || '' : '',
    email: data.patient ? data.patient.email || '' : '',
    birthDate: data.patient ? data.patient.birthDate || '' : '',
    hospitalUuid: hospitalUuid || '',
    identityNumber: data.patient ? data.patient.identityNumber || '' : '',
    nationality: data.patient ? data.patient.nationality || '' : '',
    insurance: data.patient ? data.patient.insurance || '' : '',
    insuranceNumber: data.patient ? data.patient.insuranceNumber || '' : '',
    phone: data.patient ? data.patient.phone || '' : '',
    address: data.patient ? data.patient.address || '' : '',
    gender: data.patient ? data.patient.gender || '' : '',
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('edit.patient')}</DialogTitle>
      {loading ? <Loading /> : (
        <PatientForm
          patientId={patientId}
          initialValues={initialPatient}
          submitLabel={t('update')}
          submitAction={submitAction}
          serverError={serverError}
          setServerError={setServerError}
          submitting={submitting}
          onClose={onClose}
          formName="settings-patient-edit-form"
        />
      )}
    </Dialog>
  );
};
