import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import InfoAccordion from '../../../componentsUI/InfoAccordion';
import ArchivesTableCollapse from '../../../componentsUI/ArchivesTableCollapse';
import { CaseCollapse } from '../../Case/CaseCollapse';
import { GetFormat } from '../../../utils/functions';

const MEDICAL_CASE_ACTIVE_CLOSED = 0;
const MEDICAL_CASE_ACTIVE_OPEN = 1;

const useStyles = makeStyles((theme) => ({
  datetime: {
    margin: '.25em .375em 0',
    fontSize: '0.75em',
    color: theme.palette.grey.A200,
  },
  activeCase: {
    fontSize: '1.03125em',
    fontWeight: 700,
    color: theme.palette.primary.dark,
  },
}));

const PatientMedicalCases = ({ medicalCases }) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();

  const getTitle = (item) => (
    item && item.active === MEDICAL_CASE_ACTIVE_OPEN ? (
      <div className={classes.activeCase}>{item.title}</div>
    ) : (
      `${item.title}`
    )
  );

  const getDateTime = (item) => (
    <div className={classes.datetime}>
      {GetFormat(item.createdAt, 'DATEFORMAT')}
      {item && item.active === MEDICAL_CASE_ACTIVE_CLOSED && ` (${t('case.closed')})`}
    </div>
  );

  const sortedCases = [...medicalCases].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexCase = sortedCases[index];

    navigate(`/case/${indexCase.uuid}`);
  };

  const emptyList = !medicalCases || !medicalCases.length;
  const MedicalCasesList = () => {
    if (emptyList) return <div style={{ margin: '.5em 1em' }}>{t('patient.no.cases')}</div>;

    return (
      <ArchivesTableCollapse
        responsive={false}
        fieldNames={null}
        items={sortedCases}
        GetTitle={getTitle}
        GetSubtitle={getDateTime}
        GetCollapse={CaseCollapse}
        selected={selected}
        setSelected={setSelected}
        handleGoto={handleGoto}
      />
    );
  };

  return (
    <InfoAccordion title={t('patient.cases')} Item={MedicalCasesList} expanded={!emptyList} />
  );
};

export default PatientMedicalCases;
